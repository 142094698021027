import { css, html, LitElement, nothing } from "lit"
import {
  atBreakpoint,
  brandonGrotesqueText,
  elevated,
  interactiveObject,
  interactivePseudoClasses,
  sentenceCase,
} from "#js/components/styles"
import { getUrlWithTracking } from "#js/components/utm"
import { debounce } from "#js/components/utils"
import { styleMap } from "lit/directives/style-map.js"

/**
 * A button element that can be used to trigger an action or navigate to a new page.
 */
export class Button extends LitElement {
  static properties = {
    type: { type: String },
    name: { type: String },
    value: { type: String },
    disabled: { type: Boolean },
    class: { type: String },
    btnStyle: { type: String },
    href: { type: URL },
    target: { type: String },
  }

  static styles = css`

    ${
    atBreakpoint(
      "mobile",
      css`
      .pagination-button {
        max-width: 34px;
        max-height: 34px;
      }
      :host{
        width:100%;
      }
    `,
    )
  }

    a, button {
      ${brandonGrotesqueText}
      ${interactiveObject}
      ${sentenceCase}
      border: none;
      appearance: none;
      text-decoration: none;
      background: none;
      display: inline-block;
      height: fit-content;
      box-sizing: border-box;
      border-radius: 100vh;
      width: 100%;
      font-size: 1em;
      font-weight: bold;
      text-align: center;
      line-height: 1em;
      padding: 0.75em 1em;
      white-space: nowrap;
      color: var(--interactive-color);

      &.pagination-button {
        width: 40px;
        height: 40px;
        color: var(--interactive-color);
        display: flex;
        justify-content: center;
        align-items: center;
      }

      &.info-button {
        padding:0;
      }

      &.elevated {
        ${elevated}
        background-color: var(--white);
        --elevation-shadow-color: var(--interactive-shadow-color);
      }

      &.filled {
        background-color: var(--interactive-color);
        color: var(--accessible-interactive-color);

        ${
    interactivePseudoClasses(css`
          outline-color: var(--interactive-color);
        `)
  }
      }

      &.tonal {
        background-color: var(--interactive-tonal-color);
        color: var(--black-87);

        ${
    interactivePseudoClasses(css`
          color: var(--black-87);
        `)
  }
      }

      &.outlined {
        border: 0.1em solid var(--interactive-color);
        color: var(--interactive-color);
        padding: 0.65em 0.9em;
      }

      &.round {
        padding: 0.35em;
        border-radius: 100vh;
        aspect-ratio: 1;
        width: auto;
        height: 2em;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  `

  render() {
    if (this.href) {
      return html`
        <a href=${this.href}
           class=${this.class || nothing}
           style=${this.btnStyle || nothing}
           target=${this.target || "_self"}>
          <slot></slot>
        </a>
      `
    }
    return html`
      <button @click=${this.onClick.bind(this)}
              type=${this.type || nothing}
              name=${this.name || nothing}
              value=${this.value || nothing}
              class=${this.class || nothing}
              style=${this.btnStyle || nothing}
              ?disabled=${this.disabled}>
        <slot></slot>
      </button>
    `
  }

  onClick(event) {
    const form = this.closest("form")
    if (form) {
      this.shadowRoot.querySelector("button").disabled = true
      if (this.type === "submit") {
        if (form.hasAttribute("hx-post")) {
          form.dispatchEvent(new Event("submit"))
        } else {
          form.submit()
        }
      }
      if (this.type === "reset") {
        if (form.hasAttribute("hx-post")) {
          form.dispatchEvent(new Event("reset"))
        } else {
          form.reset()
        }
      }
    }
  }
}

globalThis.customElements.define("ui-button", Button)

class Share extends LitElement {
  static properties = {
    title: { type: String },
    text: { type: String },
    url: { type: URL },
  }

  static styles = css`
    :host {
      flex: 1 0 auto;
      text-align: right;
    }

    svg {
      width: 1.3em;
      height: 1.3em;
      cursor: pointer;
      vertical-align: middle;
    }
  `

  constructor() {
    super()
    this.url = new URL(globalThis.location.href)
    this.title = document.title
    this.text = document.querySelector('meta[property="description"]')?.getAttribute(
      "content",
    )
  }

  render() {
    const shareData = this.getShareData()
    if (navigator.canShare !== undefined && navigator.canShare(shareData)) {
      return html`
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
             viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
             stroke-linecap="round" stroke-linejoin="round"
             @click="${this.onClick.bind(this)}">
          <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
          <path d="M6 12m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0"/>
          <path d="M18 6m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0"/>
          <path d="M18 18m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0"/>
          <path d="M8.7 10.7l6.6 -3.4"/>
          <path d="M8.7 13.3l6.6 3.4"/>
        </svg>
      `
    } else {
      console.debug("Web Share API not supported")
      return nothing
    }
  }

  getShareData() {
    return {
      title: this.title,
      text: this.text,
      url: getUrlWithTracking(this.url.toString(), {
        utm_source: "share",
        utm_medium: "social",
      }),
    }
  }

  async onClick(event) {
    const shareData = this.getShareData()
    console.debug(shareData)
    try {
      await navigator.share(shareData)
    } catch (error) {
      // Ignore AbortError - caused by the user cancelling the share dialog
      if (error.name !== "AbortError") {
        throw error
      }
    }
  }
}

globalThis.customElements.define("ui-share", Share)

/**
 * A wrapper for the html [`<picture>`](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/picture) element. Designed to work with the default
 * serialized image from [django-pictures](https://pypi.org/project/django-pictures/). To be used withing a lit component.
 * @param {object} picture - The picture object.
 * @param {string} picture.url - The url of the image.
 * @param {number} picture.width - The width of the image.
 * @param {number} picture.height - The height of the image.
 * @param {object} picture.ratios - The picture ratios object.
 * @param {string} alt - The alt text for the image.
 * @param {string} ratio - The aspect ratio of the image.
 * @param {object} pictureAttrs - The attributes for the picture element.
 * @param {object} imgAttrs - The attributes for the img element.
 * @returns {string} - The picture element as a string.
 */
export function picture(
  { url, width, height, ratios },
  alt,
  ratio,
  pictureAttrs = {},
  imgAttrs = {},
) {
  return `
    <picture ${attrsToString(pictureAttrs)}>
      ${
    Object.entries(ratios[ratio].sources).map(([type, srcset]) =>
      `<source type="${type}"
                    srcset="${
        Object.entries(srcset).map(([px, url]) => `${url} ${px}w`).join(", ")
      }" sizes="${ratios[ratio].media}">`
    ).join("")
  }
      <img src="${url}"
           width="${width}"
           height="${height}"
           alt="${alt}"
           ${attrsToString(imgAttrs)}/>
    </picture>
  `
}

/**
 * Convert object to string of HTML attributes.
 * @param {object} attrs - Key-value pairs of attributes.
 * @returns {string} - String of HTML attributes.
 */
export function attrsToString(attrs) {
  return Object.entries(attrs).map(([k, v]) => `${k}="${v}"`).join(" ")
}

/**
 * Wrapper to scroll slotted overflow content horizontally.
 *
 * Adds left and right arrows to scroll the content.
 * Updates the visibility of the arrows based on the scroll position.
 * Scrolls to the active element on mount.
 */
class VerticalScroll extends LitElement {
  static properties = {
    _scrollDistance: { type: Number, attribute: false },
  }

  static styles = css`
    :host {
      position: relative;
      padding: 0 2.5em;
    }

    .arrow {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      font-size: 1.25em;
      height: 1.5em;
      width: 1.5em;
      cursor: pointer;
      color: var(--black-87);
      transition: opacity 250ms;
      background-color: var(--white);
      border-radius: 100vh;
      border: thin solid var(--black-10);

      &::before {
        display: inline-block;
        content: "";
        height: 0.49em;
        aspect-ratio: 1;
        margin: 0.5em;
        box-sizing: border-box;
        transform: rotate(45deg) translate(-0.05em, 0.05em);
        transition: transform 150ms ease-in-out;
        border-top-width: 0.1em;
        border-top-style: solid;
        border-right-width: 0.1em;
        border-right-style: solid;
        vertical-align: text-top;
        border-color: var(--black-87);
      }
    }

    .arrow--left {
      &::before {
        transform: translate(0.1em, 0) scaleX(-1) rotate(45deg);
      }
    }
  `

  firstUpdated() {
    const slot = this.shadowRoot.querySelector("slot")
    this._container = slot.assignedElements({ flatten: true })[0]
    this._container.addEventListener(
      "scroll",
      debounce(() => this.requestUpdate(), 150).bind(this),
    )
    this._scrollDistance = this._container.clientWidth / 2

    // set the scroll position to the active element
    setTimeout(() => {
      const activeElement = this._container.querySelector(`[aria-current="true"]`)
      if (activeElement) {
        this._container.scrollLeft = activeElement.offsetLeft - this._scrollDistance
        this.requestUpdate()
      }
    }, 150)
  }

  render() {
    return html`
      <div class="arrow arrow--left"
           @click="${() => this.scroll(this._scrollDistance * -1)}"
           style="${styleMap(this.getLeftArrowStyles())}">
      </div>
      <slot></slot>
      <div class="arrow arrow--right"
           @click="${() => this.scroll(this._scrollDistance)}"
           style="${styleMap(this.getRightArrowStyles())}">
      </div>
    `
  }

  getLeftArrowStyles() {
    return {
      opacity: this._container && this._container.scrollLeft === 0 ? 0.3 : 1,
      left: 0,
    }
  }

  getRightArrowStyles() {
    return {
      opacity: (
        this._container &&
          this._container.scrollWidth -
                this._container.scrollLeft -
                this._container.clientWidth <= 0
          ? 0.3
          : 1
      ),
      right: 0,
    }
  }

  scroll(amount) {
    this._container.scrollBy({ left: amount, behavior: "smooth" })
  }
}

globalThis.customElements.define("ui-vertical-scroll", VerticalScroll)

class Icon extends LitElement {
  static properties = {
    name: { type: String },
  }

  static styles = css`
    :host {
      display: inline-block;
      color: var(--black-87);
      line-height: 1;
      vertical-align: sub;
    }

    svg {
      width: 1.3em;
      height: 1.3em;
    }
  `

  render() {
    return html`
      <svg
           width="24"
           height="24"
           viewBox="0 0 24 24"
           stroke-width="1.25"
           stroke="currentColor"
           fill="none"
           stroke-linecap="round"
           stroke-linejoin="round">
        <use href="${globalThis.tablerIconSprite}#tabler-${this.name}"></use>
      </svg>
    `
  }
}

globalThis.customElements.define("tabler-icon", Icon)
